<template>
  <v-navigation-drawer width="100%" style="height: 92vh">
    <v-toolbar dense flat class="mt-2 mb-0">
      <v-toolbar-title>Encuestadores activos</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card flat class="mx-auto scroll">
      <!-- <v-switch v-model="isDateFiltered" class="mx-5" label="Filtrar por fecha"/> -->
      <v-form>
        <v-row v-if="isDateFiltered" no-gutters dense align="center">
          <v-btn text icon @click="dateBack">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="slide-y-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-col sm="6">
                <v-text-field
                  v-model="date"
                  label="Fecha"
                  prepend-icon="mdi-calendar-range"
                  readonly
                  v-on="on"
                >
                </v-text-field>
              </v-col>
            </template>
            <v-date-picker
              ref="picker"
              v-model="pickerDate"
              :picker-date.sync="pickerDate"
              :max="todayDate"
              show-current="true"
              @input="dateMenu = false"
              locale="es"
            >
            </v-date-picker>
          </v-menu>
          <v-btn :disabled="isAtLastDate" text icon @click="dateNext">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn small sm="1" class="ma-0 pa-0" text @click="today">Hoy</v-btn>
        </v-row>
        <v-row dense>
          <v-select
            outlined
            dense
            hide-details
            class="ma-2"
            item-value="id"
            item-text="name"
            v-model="selectedIssueTypes"
            :items="getIssueTypesIssueTypes"
            label="Tipo de incidencia"
            multiple
            @blur="onIssueTypesChanges"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip class="mt-2" v-if="index === 0">
                <v-avatar left>
                  <v-img :src="item.iconUrl"></v-img>
                </v-avatar>
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption"
                >(+{{ selectedIssueTypes.length - 1 }} otras)</span
              >
            </template>
            <template v-slot:item="{ item }">
              <v-avatar small left>
                <v-img :src="item.iconUrl"></v-img>
              </v-avatar>
              {{ item.name }}
            </template>
          </v-select>
        </v-row>

        <v-row dense>
          <v-select
            outlined
            dense
            hide-details
            class="ma-2"
            item-value="id"
            item-text="name"
            v-model="selectedGroups"
            :items="getAllGroups"
            label="Grupos"
            multiple
            @blur="onGroupChanges"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip class="mt-2" v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption"
                >(+{{ selectedGroups.length - 1 }} otras)</span
              >
            </template>
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
          </v-select>
        </v-row>

        <v-row dense>
          <v-autocomplete
            outlined
            dense
            hide-details
            class="ma-2"
            item-value="id"
            item-text="displayName"
            v-model="selectedUsers"
            :items="usersResults"
            label="Usuario"
            multiple
            @blur="onUsersChanges"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip class="mt-2" v-if="index === 0">
                <span>{{ item.displayName }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption"
                >(+{{ selectedUsers.length - 1 }} otras)</span
              >
            </template>
            <template v-slot:item="{ item }">
              {{ item.displayName }}
            </template>
          </v-autocomplete>
        </v-row>
      </v-form>
      <v-divider></v-divider>
      <v-skeleton-loader
        :loading="getIssuesIsLoading"
        :transition="transition"
        type="list-item-avatar-two-line"
      >
        <template v-if="getIssuesIssues.length > 0">
          <v-list two-line dense subheader>
            <v-list-item-group active-class="primary--text">
              <template v-for="(issue, index) in paginatedIssues">
                <v-list-item :key="issue.id" @click="issueClicked(issue)">
                  <template v-slot:default="">
                    <v-list-item-avatar class="mr-2">
                      <v-img :src="issue.issueType.iconUrl"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="issue.workflow.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="issue.issueType.name"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-text="issue.place.name"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-chip
                          class="mt-1"
                          small
                          dark
                          :color="issue.issueStatus.color"
                        >
                          {{ issue.issueStatus.name }}
                        </v-chip>
                      </v-list-item-subtitle>
                      <!-- <v-list-item-action-text v-text="dateFromNow(issue.createdAt)"></v-list-item-action-text> -->
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="index + 1 < getIssuesIssues.length"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </template>
        <template v-else>
          <v-card dense flat jusify-center class="ma-4 grey--text"
            >No hay resulados para los criterios de búsqueda</v-card
          >
        </template>
      </v-skeleton-loader>
    </v-card>
    <div align="end">
      <v-pagination
        v-model="currentPageNumber"
        :length="issuesPagesCount"
      ></v-pagination>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { addDays, format, to } from '@/helpers'
import { dateFromNow } from '@/mixins'
import {
  ISSUES_GET_ISSUE,
  ISSUES_GET_ISSUES,
  ISSUE_TYPES_GET_ISSUE_TYPES
} from '@/store/actions.type'
import {
  ISSUES_REMOVE_ISSUE,
  ISSUES_SET_IS_DATE_FILTERED,
  ISSUES_SET_SELECTED_DATE,
  ISSUES_SET_SELECTED_GROUPS,
  ISSUES_SET_SELECTED_ISSUE_TYPES,
  ISSUES_SET_SELECTED_USERS
} from '@/store/mutations.type'
import { parseISO } from 'date-fns'
import { es } from 'date-fns/esm/locale'
import { mapGetters } from 'vuex'
export default {
  name: 'IssuesSidebar',
  props: {},
  data() {
    return {
      dateMenu: false,
      todayDate: new Date().toISOString().substr(0, 10),
      pickerDate: new Date().toISOString().substr(0, 10),
      selectedIssueTypes: [],
      selectedGroups: [],
      selectedUsers: [],
      transition: 'fade-transition',
      isDateFiltered: true,
      issuesPerPage: 10,
      issueId: null,
      currentPageNumber: 1
    }
  },
  mounted() {
    this.getIssueTypes().then(() => {
      const date = new Date()
      this.$store.commit(ISSUES_SET_SELECTED_DATE, date)
      this.$store.commit(ISSUES_SET_IS_DATE_FILTERED, true)
      this.pickerDate = date.toISOString().substr(0, 10)
      this.getIssues()
    })
    if (this.getIssuesSelectedDate) {
      this.pickerDate = this.getIssuesSelectedDate.toISOString().substr(0, 10)
    }
  },
  computed: {
    usersResults() {
      const groups = this.selectedGroups
      let usersResults = []
      usersResults = this.getUsers.filter(user =>
        groups.length === 0
          ? user
          : user.groups.some(gp => groups.includes(gp.id))
      )
      return usersResults
    },
    date() {
      return format(this.getIssuesSelectedDate, 'dd / MM / yyyy', {
        locale: es
      })
    },
    paginatedIssues() {
      var startIndex = (this.currentPageNumber - 1) * this.issuesPerPage
      var endIndex = this.currentPageNumber * this.issuesPerPage
      return this.getIssuesIssues.slice(startIndex, endIndex)
    },
    issuesPagesCount() {
      return Math.ceil(this.getIssuesIssues.length / this.issuesPerPage)
    },
    isAtLastDate() {
      const tomorrow = addDays(this.getIssuesSelectedDate, 1)
      return tomorrow.getTime() > new Date().getTime()
    },
    ...mapGetters([
      'getIssuesIssues',
      'getIssuesIsLoading',
      'getIssuesSelectedDate',
      'getIssueTypesIssueTypes',
      'getIssueTypesIsLoading',
      'getUsers',
      'getAllGroups'
    ])
  },
  watch: {
    pickerDate(date) {
      date = parseISO(date)
      this.updateDate(date)
    },
    isDateFiltered(value) {
      this.$store.commit(ISSUES_SET_IS_DATE_FILTERED, value)
      this.getIssues()
    }
  },
  methods: {
    dateBack() {
      const newDate = addDays(this.getIssuesSelectedDate, -1)
      this.updateDate(newDate)
      this.$store.commit(ISSUES_REMOVE_ISSUE)
      this.getIssues()
    },
    dateNext() {
      const newDate = addDays(this.getIssuesSelectedDate, 1)
      this.updateDate(newDate)
      this.$store.commit(ISSUES_REMOVE_ISSUE)
      this.getIssues()
    },
    today() {
      const newDate = new Date()
      this.updateDate(newDate)
      this.$store.commit(ISSUES_REMOVE_ISSUE)
      this.getIssues()
    },
    updateDate(date) {
      this.$store.commit(ISSUES_SET_SELECTED_DATE, date)
      this.pickerDate = date.toISOString().substr(0, 10)
      this.getIssues()
    },
    issueClicked(issue) {
      this.issueId = issue.id
      this.getIssue()
    },
    async getIssue() {
      const [err] = await to(
        this.$store.dispatch(ISSUES_GET_ISSUE, this.issueId)
      )
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    },
    async getIssues() {
      const [err] = await to(this.$store.dispatch(ISSUES_GET_ISSUES))
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    },
    async getIssueTypes() {
      const [err, data] = await to(
        this.$store.dispatch(ISSUE_TYPES_GET_ISSUE_TYPES)
      )
      if (err) {
        this.error = err
        this.isLoading = false
      }
      const selected = data.map(elem => elem.id)
      this.$store.commit(ISSUES_SET_SELECTED_ISSUE_TYPES, selected)
      this.selectedIssueTypes = selected
      this.isLoading = false
    },
    onUsersChanges() {
      this.$store.commit(ISSUES_SET_SELECTED_USERS, this.selectedUsers)
      this.getIssues()
    },
    onGroupChanges() {
      this.$store.commit(ISSUES_SET_SELECTED_GROUPS, this.selectedGroups)
      this.getIssues()
    },
    onIssueTypesChanges() {
      this.$store.commit(
        ISSUES_SET_SELECTED_ISSUE_TYPES,
        this.selectedIssueTypes
      )
      this.getIssues()
    }
  },
  components: {},
  mixins: [dateFromNow]
}
</script>
